<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">{{ $t('message.minimum_weight_list') }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <template>
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-checkbox v-model="checkAll"> {{ $t('message.select_all') }} </el-checkbox>
                </el-col>
                <el-col :span="4" v-for="(cargo_type, index) in cargoTypes" :key="'aaa' + index">
                  <el-input size="mini" :placeholder="cargo_type.name" v-model="units[cargo_type.type]" @input="updateMinWeight($event, cargo_type.type)" type="number"></el-input>
                </el-col>
              </el-row>
              <hr>
            </template>

            <template>
                <el-table
                :data="tariffList"
                stripe
                style="width: 100%">
                    <el-table-column
                    sortable
                    :sort-method="sortByChecked"
                    :label="$t('message.name')"
                    >
                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.checked">{{ scope.row.name }}</el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="date"
                    :label="$t('message.from_filial')"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.from_filial ? scope.row.from_filial.name : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    :label="$t('message.to_filial')"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.to_filial ? scope.row.to_filial.name : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-for="(cargo_type, index) in cargoTypes"
                        :key="'aaa' + index"
                        width="200px"
                        :prop="cargo_type.type"
                        :label="cargo_type.name"
                        >
                            <template slot-scope="scope">
                                <el-input size="mini" :placeholder="cargo_type.name" v-model="scope.row[cargo_type.type]" type="number"></el-input>
                            </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  
  mixins: [form, drawer],
  props: ['selectedItem'],
  data() {
    return {
        tariffList: [],
        units: {},
        checkAll: false,
    };
  },
  created() {
    this.updateCargoTypes()
      .then(res => {
        this.cargoTypes.forEach(element => {
          this.$set(this.units, element.type, 0);
        });
      });
  },
  computed: {
      ...mapGetters({
          tariffs: "minimumWeightLimit/list",
          cargoTypes: "deals/cargoTypes",
      }),
  },
  watch: {
    checkAll: {
        handler: async function(newVal, oldVal) {
          this.tariffList.forEach(el => this.$set(el, 'checked', newVal));
        },
        deep: true,
        immediate: true
    },
  },
  methods: {
      ...mapActions({
          save: "minimumWeightLimit/store",
          updateCargoTypes: "deals/cargoTypes",
          updateTariffs: "minimumWeightLimit/partnerDirectionTariffs",
      }),

      updateMinWeight (val, type) {
        this.tariffList.forEach(el => {
          if(el.checked){
            this.$set(el, type, val)
          }
        });
      },
      
      sortByChecked(a, b){
        if(a.checked > b.checked){
          return -1;
        }
      },
      afterLeave() {
        this.tariffList = [];
      },
      afterOpen(){
        this.updateTariffs({client_id: this.selectedItem.id}).then(res => {
            this.tariffList = JSON.parse(JSON.stringify(this.tariffs));
        })
      },
      submit(close = true) {
        this.form = this.tariffList.map(el => {
          return {
            ...el,
            'client_id': this.selectedItem.id,
            'direction_tariff_id': el.tariff_id,
          }
        })
        this.loadingButton = true;
        this.save(this.form)
            .then((res) => {
                this.loadingButton = false;
                this.$alert(res);
                if (close) this.close();
            })
            .catch((err) => {
                this.loadingButton = false;
                this.$alert(err);
            });
      },
  },
};
</script>
<style>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
</style>